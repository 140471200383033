/* To add a new modal, follow the format seen in '_report_issue_html.erb'
The modal-container, modal-clickoff, modal-cancel, and modal-box divs are all necessary. The rest can be changed
for whatever the new modal needs to do.
When creating a button to open the Modal, give it the class "modal-btn-open" and the attribute
'data-modal= modal' where 'modal' is the ID given to the top level 'modal-container' div. */

const modals = document.querySelectorAll('.modal-container'),
    modal_buttons = document.querySelectorAll('.modal-btn-open');

modal_buttons.forEach(btn => {
    modal = document.querySelector('#' + btn.dataset.modal)
    btn.addEventListener('mouseup', OpenModal.bind(this,modal));
})

modals.forEach(modal => {
    modal.querySelector('.modal-clickoff').addEventListener('mouseup', CloseModal.bind(this,modal))
    modal.querySelector('.modal-btn-cancel').addEventListener('mouseup', CloseModal.bind(this,modal))
    modal.addEventListener("transitionend", function(){
        if(!ModalShowing(modal)){
            modal.style.display="none";
        }
    })
});


function CloseModal(modal){
    modal.querySelector('.modal-box').classList.remove("modal-box-show");
    modal.classList.remove("modal-show");
    modal.querySelectorAll('.modal-reset').forEach(field => {
        field.value = "";
    })
}

function OpenModal(modal){
    modal.style.display="flex"
    setTimeout(() => modal.classList.add("modal-show"),0);
    setTimeout(() => modal.querySelector('.modal-box').classList.add("modal-box-show"),0);
}

function ModalShowing(modal){
    return modal.classList.contains("modal-show")
}
